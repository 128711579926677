/* .msnt-photo-thumb-gallery {
  width: 100%;
  max-width: 100%;
  position: relative;
} */
.msnt-photo-thumb-gallery.inline.border-line .msnt-photo-thumb-gallery-main-photo picture:after{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000000;
  box-sizing: border-box;
}
/* .msnt-photo-thumb-gallery-photos-list .thumb-img {
  max-height: 120px;
}

.msnt-photo-thumb-gallery-main-photo {
  background: var(--red-color);
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
} */

/* .msnt-photo-thumb-gallery-main-photo-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 0;
  line-height: 0;
  background: var(--black-color);
}

.msnt-photo-thumb-gallery-main-photo-box picture{
  height:100%;
}

.msnt-photo-thumb-gallery-main-photo-box picture > img,
.msnt-photo-thumb-gallery-main-photo-box > img {
  height: 100%;
  max-width: 100%;
} */

/*
.msnt-photo-thumb-gallery-photos-list.not-ready{
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0;
    line-height: 0;
    height: 50px;
}
.msnt-photo-thumb-gallery-photos-list.not-ready > a{
    display: inline-block;
    vertical-align: top;
    text-align: center;
    height: 100%;
}
.msnt-photo-thumb-gallery-photos-list.not-ready > a > img{
    height: 100%;
}

@media only screen and (min-width: 768px) {
    .msnt-photo-thumb-gallery-photos-list.not-ready{
        height: 85px;
    }
}
@media only screen and (min-width: 1024px) {
    .msnt-photo-thumb-gallery-photos-list.not-ready{
        height: 120px;
    }
}
*/

@media only screen {
  /* .msnt-photo-thumb-gallery-photos-list {
    overflow: hidden;
    white-space: nowrap;
  }

  .msnt-photo-thumb-gallery-photos-list > span,
  .msnt-photo-thumb-gallery-photos-list > div {
    width: 106px;
    height: 60px;
    text-align: center;
    overflow: hidden;
    border-top: 5px solid transparent;
    display: inline-block;
    position: relative;
    cursor: pointer;
  } */

  .msnt-photo-thumb-gallery-photos-list > span .responsive .spinner_loader,
  .msnt-photo-thumb-gallery-photos-list > span .responsive #spinner_loader,
  .msnt-photo-thumb-gallery-photos-list > div .responsive .spinner_loader,
  .msnt-photo-thumb-gallery-photos-list > div .responsive #spinner_loader {
    border: 6px solid var(--white-color);
    border-top: 6px solid var(--main-link-color);
    width: 20px;
    height: 20px;
    margin: -15px 0 0 -10px;
  }

  .msnt-photo-thumb-gallery-photos-list > span.lslide.active,
  .msnt-photo-thumb-gallery-photos-list > span:hover,
  .msnt-photo-thumb-gallery-photos-list > div.lslide.active,
  .msnt-photo-thumb-gallery-photos-list > div:hover {
    border-top-color: var(--main-link-color);
  }

  .msnt-photo-thumb-gallery-photos-list > span > a > picture,
  .msnt-photo-thumb-gallery-photos-list > div > a > picture {
    height: 100%;
  }

  .msnt-photo-thumb-gallery-photos-list > span > a > picture img,
  .msnt-photo-thumb-gallery-photos-list > div > a > picture img {
    width: 100%;
  }
}
/* @media only screen and (min-width: 480px) {
  .msnt-photo-thumb-gallery-photos-list > span,
  .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content {
    width: 133px;
    height: 75px;
  }

  .msnt-photo-thumb-gallery-photos-list > span.custom_size,
  .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content.custom_size {
    width: 100px;
    height: 75px;
  }
}

@media only screen and (min-width: 768px) {
  .msnt-photo-thumb-gallery-photos-list > span,
  .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content {
    width: 160px;
    height: 90px;
  }

  .msnt-photo-thumb-gallery-photos-list > span.custom_size,
  .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content.custom_size {
    width: 120px;
    height: 90px;
  }
} */

/* @media only screen and (min-width: 1400px) {
  .msnt-photo-thumb-gallery:not(.inline) .msnt-photo-thumb-gallery-photos-list > span,
  .msnt-photo-thumb-gallery:not(.inline) .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content {
    width: 213px;
    height: 120px;
  }

  .msnt-photo-thumb-gallery:not(.inline) .msnt-photo-thumb-gallery-photos-list > span.custom_size,
  .msnt-photo-thumb-gallery:not(.inline) .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content.custom_size {
    width: 160px;
    height: 120px;
  }
} */

@media only screen {
  .content-wrapper .msnt-photo-thumb-gallery .lSAction > a,
  .content-area-box .msnt-photo-thumb-gallery .lSAction > a,
  .oem-detail_header-wrapper .msnt-photo-thumb-gallery .lSAction > a {
    width: 25px;
    height: 25px;
    background: rgba(var(--black), 0.75);
    top: auto;
    bottom: 50%;
    margin-bottom: -14px;
    text-align: center;
    transform: rotate(0) /*!rtl: rotate(180deg)*/;
  }

  .msnt-photo-thumb-gallery .lSAction > a svg {
    fill: var(--white-color);
    width: 100%;
    height: 100%;
  }

  .msnt-photo-thumb-gallery .lSAction > .lSNext {
    right: 0;
  }

  .msnt-photo-thumb-gallery .lSAction > .lSPrev {
    left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .msnt-photo-thumb-gallery .lSSlideWrapper .lSAction > a {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (min-width: 1400px) {
  .msnt-photo-thumb-gallery .lSSlideWrapper .lSAction > a {
    width: 44px;
    height: 44px;
    margin-bottom: -24px;
  }

  .oem-detail_header-wrapper .msnt-photo-thumb-gallery .lSAction > a {
    margin-bottom: 0;
  }
}

@media only screen {
  .msnt-photo-thumb-gallery .lg-custom-content.lg-custom-content-related.lslide .lg-custom-content_columns {
    display: none;
  }

  .msnt-photo-thumb-gallery .lg-custom-content.lg-custom-content-related.lslide .lg-custom-content_thumb {
    cursor: pointer;
  }
}

@media only screen {
  .msnt-photo-thumb-gallery-count {
    position: absolute;
    display: block;
    bottom: 5px;
    left: 5px;
    z-index: 5;
    background: rgba(var(--black), 0.2);
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    cursor: default;
    pointer-events: none;
  }

  .msnt-photo-thumb-gallery-count .value {
    display: inline-block;
    vertical-align: middle;
    background: var(--white-bg-color);
    color: var(--main-text-color-special);
    min-width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 4px;
    margin-right: 5px;
  }

  .msnt-photo-thumb-gallery-count .label {
    display: inline-block;
    vertical-align: middle;
    color: var(--white-color);
  }
}

@media only screen {
  .msnt-photo-thumb-gallery-photos-list > span:nth-child(7) {
    position: relative;
  }

  .msnt-photo-thumb-gallery-photos-list > span:nth-child(7)::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(var(--white), 0.75);
    content: '';
    cursor: pointer;
  }

  .msnt-photo-thumb-gallery-photos-list > span:nth-child(7)::before {
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    color: rgb(111, 111, 111);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    content: '+';
    z-index: 1;
  }

  .msnt-photo-thumb-gallery-photos-list > span:nth-child(7):hover::before {
    color: rgb(64, 64, 64);
  }

  .msnt-photo-thumb-gallery-photos-list > span:hover:nth-child(7)::after {
    background: rgba(var(--white), 0.5);
  }

  .msnt-photo-thumb-gallery-photos-list > span:nth-child(n + 8),
  .msnt-photo-thumb-gallery-photos-list > div.lg-custom-content:nth-child(n+8) {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    display: none;
  }
}
